/**
 * Plugin that asynchronously loads the Here maps scripts. The consuming component needs to call the load
 * method to get safe access to the API
 */

import config from '@/config/config.js';

export default {
  install(Vue) {
    const majorVersion = config.here.majorVersion;
    const minorVersion = config.here.minorVersion;
    const url = `https://js.api.here.com/${majorVersion}/${minorVersion}`;
    Vue.prototype.$heremaps = {
      async load() {
        if (typeof window.H === 'undefined') {
          await loadJs(`${url}/mapsjs-core.js`);
          await loadJs(`${url}/mapsjs-service.js`);
          const promiseUi = loadJs(`${url}/mapsjs-ui.js`);
          const promiseEvents = loadJs(`${url}/mapsjs-mapevents.js`);
          const promiseCss = loadCss(`${url}/mapsjs-ui.css`);
          await Promise.all([promiseUi, promiseEvents, promiseCss]);
          return window.H;
        }
        return window.H;
      },
    };

    Vue.component('Heremap', () => import('@/components/Heremap'));
  },
};

function loadJs(srcUrl) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.src = srcUrl;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      resolve();
    };

    script.onerror = reject;
    script.onabort = reject;

    document.head.appendChild(script);
  });
}

function loadCss(srcUrl) {
  return new Promise((resolve) => {
    const cssTag = document.createElement('link');
    cssTag.type = 'text/css';
    cssTag.rel = 'stylesheet';
    cssTag.href = srcUrl;
    cssTag.onload = () => {
      resolve();
    };
    document.head.append(cssTag);
  });
}
