<script>
/**
 * Global mixin to provide helper utilities
 */
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      engineerIconWidth: this.$omwConfig.display.engineerIcon.width,
      engineerIconHeight: this.$omwConfig.display.engineerIcon.height,
      engineerIconAnchorX: this.$omwConfig.display.engineerIcon.anchorX,
      engineerIconAnchorY: this.$omwConfig.display.engineerIcon.anchorY,
      activityIconWidth: this.$omwConfig.display.activityIcon.width,
      activityIconHeight: this.$omwConfig.display.activityIcon.height,
      activityIconAnchorX: this.$omwConfig.display.activityIcon.anchorX,
      activityIconAnchorY: this.$omwConfig.display.activityIcon.anchorY,
      windowWidth: window.innerWidth,
      tooLateToRebookCancelStatuses: this.$omwConfig.reschedule.tooLateToRebookCancelStatuses || [
        'cancelled',
        'canceled',
        'completed',
        'enroute',
      ],
    };
  },
  computed: {
    ...mapGetters(['activityDetails', 'engineerDetails', 'apptInFuture', 'apptInPast', 'token', 'clickOrigin']),
    passcodeEnabled() {
      return this.$omwConfig?.omwServer?.passcodeEnabled;
    },
    dataLoaded() {
      // Use date field as a proxy for know if data is loaded as activityDetails default to {} for Vue rendering reasons
      return !!this.activityDetails.date;
    },
    allowUserRebook() {
      // We want to default to true if not specified
      const contactCentreRebook =
        typeof this.$omwConfig?.reschedule?.allowContactCentreRebook !== 'undefined'
          ? this.$omwConfig?.reschedule?.allowContactCentreRebook
          : true;
      if (contactCentreRebook) return true; // If contact centre user can rebook, this computed variable should always return true

      // Contact centre users aren't allowed to rebook so check if this is coming from a contact centre request
      return this.clickOrigin !== 'portal';
    },
    notesEnabled() {
      return this.$omwConfig?.notes?.enabled;
    },
    photosEnabled() {
      return this.$omwConfig?.photos?.enabled;
    },
    marketingEnabled() {
      return this.$omwConfig?.marketing?.enabled;
    },
    trustpilotEnabled() {
      return this.$omwConfig?.trustpilot?.enabled;
    },
    contactUsEnabled() {
      return !this.$omwConfig.display.disableContactUs;
    },
    calendarImportEnabled() {
      return this.$omwConfig?.calendarImport?.enabled && this.activityDetails?.status === 'pending';
    },
    additionalInfoEnabled() {
      return this.$omwConfig?.display?.additionalInfo?.enabled;
    },
    separatorEnabled() {
      return this.$omwConfig?.display.separatorEnabled;
    },
    feedbackEnabled() {
      return this.$omwConfig?.feedback?.enabled;
    },
    notificationEnabled() {
      return this.$omwConfig?.display?.notification?.show;
    },
    apptToday() {
      return !this.apptInFuture && !this.apptInPast;
    },
    language() {
      return navigator.language;
    },
    engineerName() {
      if (this.apptInFuture) {
        return this.$t('future-engineer-name');
      }
      return this.engineerDetails.nameOverride || this.engineerDetails.name || this.$t('unknown-engineer-name');
    },
    isUnassigned() {
      return this.engineerName === this.$t('future-engineer-name');
    },
    isMobile() {
      return this.windowWidth <= 768;
    },
    showDisclaimer() {
      return this?.activityDetails?.status === 'enroute' || this?.activityDetails?.status === 'pending';
    },
    activityIcon() {
      return this.$omwConfig.display.activityIcon.svg;
    },
    engineerIcon() {
      return this.$omwConfig.display.engineerIcon.svg;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  mounted() {
    window.addEventListener('resize', this.widthListenerCallBack);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.widthListenerCallBack);
  },
  methods: {
    ...mapActions(['setToken', 'setLoading', 'setError', 'setOmwData']),
    getPasscode() {
      return this.passcodeEnabled ? localStorage.getItem('passcode') : undefined;
    },
    storePasscode(passcode) {
      localStorage.setItem('passcode', passcode);
    },
    getPreviouslyLoggedIn() {
      return localStorage.getItem('previouslyLoggedIn') === 'true';
    },
    storePreviouslyLoggedIn(flag) {
      localStorage.setItem('previouslyLoggedIn', flag.toString());
    },
    accessGranted() {
      return !this.passcodeEnabled || this.getPreviouslyLoggedIn();
    },
    checkAccess() {
      if (!this.token) {
        if (!this.accessGranted()) {
          this.routeToLoadingPage(this.$route?.query?.token);
          return false;
        }
        this.setToken(this.$route?.query?.token);
      }
      return true;
    },
    convertTrueFalseStringToBoolean(trueFalse) {
      if (trueFalse === undefined) return false;
      if (!trueFalse) {
        return false;
      }
      return trueFalse.toLowerCase() === 'true';
    },
    getOrientation(x) {
      if (x === undefined) {
        return 'desktop';
      } else {
        let y;
        if (x === 90 || x === -90) {
          y = 'landscape';
        } else {
          y = 'portrait';
        }
        return y;
      }
    },
    widthListenerCallBack() {
      this.windowWidth = window.innerWidth;
    },
    async routeToHomePage(method = 'push' /* | 'replace' */, reloadData = true) {
      if (reloadData) await this.getData();
      this.routeToPage('Home', method);
    },
    async routeToPage(name, method = 'push' /* | 'replace' */, params = undefined) {
      const query = {
        token: this.token,
      };
      if (this.clickOrigin) query.clickOrigin = this.clickOrigin;

      // If this being called as result of a preview from the portal, propagate the preview and click url
      if (this.$route?.query?.marketingPreviewUrl) query.marketingPreviewUrl = this.$route.query.marketingPreviewUrl;
      if (this.$route?.query?.clickUrl) query.clickUrl = this.$route.query.clickUrl;

      this.$router[method]({ name, query, params });
    },
    async getDataIfNeeded() {
      if (!this.dataLoaded) return this.getData();
    },
    async getData(showError = true) {
      try {
        this.setLoading(true);
        await this.setOmwData(this.getPasscode());
        this.storePreviouslyLoggedIn(true);
        return this.dataLoaded;
      } catch (err) {
        if (err.response?.status === 401) {
          this.storePreviouslyLoggedIn(false);
          this.routeToPage('Loading');
        } else if (showError) {
          this.setError(this.$t('error-appointment-not-viewable'));
        }
        return false;
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
