import Vue from 'vue';
import Vuex from 'vuex';
import { mutations, actions, getters } from './store.js';
import { state } from './state.js';
Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
