<template>
  <span>
    <b-button class="is-home-button has-text-weight-semibold" @click="goHome()">{{
      $t('rebook-home-button-label')
    }}</b-button>
  </span>
</template>

<script>
import { mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'PostProcessReturnButton',
  methods: {
    ...mapActions(['setRescheduleDetails']),
    async goHome() {
      await this.setRescheduleDetails(null);
      await this.routeToHomePage('replace');
    },
  },
});
</script>
