<template>
  <b-button
    :style="`width:${width}rem`"
    type="is-danger has-text-weight-semibold"
    size="is-small"
    @click="$emit('cancel')"
    >{{ $t('rebook-cancel-button-label') }}</b-button
  >
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AppointmentCancellationButton',
  props: {
    width: {
      type: Number,
      default: 10,
    },
  },
});
</script>
