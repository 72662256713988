import axios from 'axios';

import omwConfig from '@/config/config';
import store from '@/store';
import lang from '@/lang/lang.js';

export async function getData(passcode) {
  const token = store.getters.token;
  const clickOrigin = store.getters.clickOrigin;
  const params = {
    token,
    clickOrigin,
  };
  if (passcode) params.passcode = passcode;

  try {
    store.commit('SET_ERROR', undefined);
    const response = await axios.get(`${omwConfig.omwServer.url}`, {
      params,
      headers: {
        'x-api-key': omwConfig.omwServer.apiKey,
      },
      crossdomain: true,
      timeout: 30_000,
    });
    if (response.status === 204) {
      return await store.dispatch('setError', lang.t('error-appointment-not-viewable'));
    }
    return response.data;
  } catch (err) {
    if (err.response?.status === 401) throw err;
    if (err.response?.status === 406) {
      await store.dispatch('setError', err.response.data.message);
    } else {
      await store.dispatch('setError', lang.t('error-appointment-not-viewable'));
    }
  } finally {
    store.commit('SET_LOADING', false);
  }
}
