<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';

/**
 * This is a special component to support a route into the app where the token is provided as a path param. Such URLs only
 * to be used when distributing URLs via SMS. It redirects to the loading root, placing the token in the query params.
 */
export default defineComponent({
  computed: {
    ...mapGetters(['isLoading']),
  },
  created() {
    this.setToken(this.$route?.params?.token);
    this.routeToPage('Loading');
  },
  methods: {
    ...mapActions(['setToken']),
  },
});
</script>
