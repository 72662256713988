import configData from '@/config/config';
import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${configData?.notes?.url}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': configData.omwServer.apiKey,
  },
});

export async function getNotes(token) {
  return (await apiClient.get('', { params: { token } })).data;
}

export async function postNote(token, note) {
  return (await apiClient.post('', note, { params: { token } })).data;
}
