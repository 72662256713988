<template>
  <div class="container is-fluid">
    <p class="is-size-3 has-text-centered pt-4" v-html="message"></p>
    <div class="has-text-centered columns is-centered">
      <marketing v-if="marketingEnabled" class="column is-half pt-6" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';

import Marketing from '@/components/optional/Marketing.vue';

export default defineComponent({
  components: {
    Marketing,
  },
  data() {
    return {
      message: undefined,
    };
  },
  computed: {
    ...mapGetters(['error']),
    headerImageStyleObject() {
      return {
        maxWidth: '20em',
      };
    },
  },
  mounted() {
    this.computeErrorMsg();
  },
  methods: {
    computeErrorMsg() {
      this.$nextTick(() => {
        this.message = this.error || this.$t('error-appointment-not-viewable');
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';

h1 {
  font-size: 2rem;
  text-align: center;
  display: block;
  padding: 3em;
  font-weight: 500;
}
img {
  display: block;
  margin: auto auto;
  max-width: 50vw;
}

.logo-background {
  background-color: $header-background;
}
</style>
