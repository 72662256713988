<template>
  <div class="content" style="margin-top: -0.75em">
    <div class="columns is-mobile is-align-items-center">
      <div class="column has-text-centered">
        <img v-if="photoSource" id="imgEngineer" class="mt-1 circular--landscape" :src="photoSource" />
        <div v-if="engineerMessage" id="divEngMsg" class="has-text-weight-semibold">
          {{ engineerMessage }}
        </div>
        <div v-if="showLicencePlate && licencePlate" class="column">
          <div class="has-text-weight-semibold is-size-6">
            {{
              $t('engineer-panel-licence-plate', {
                licencePlate,
              })
            }}
          </div>
        </div>
      </div>
      <div v-if="funFact || (filteredLogos && filteredLogos.length > 0)" class="column">
        <p
          v-if="funFact"
          class="has-text-centered has-text-weight-semibold is-italic pr-4 fun-fact-padding"
          id="pEngFact"
        >
          {{ funFact }}
        </p>
        <div v-if="filteredLogos && filteredLogos.length > 0" id="divEngLogos" class="columns is-mobile">
          <div v-for="logo in filteredLogos" :key="logo.filename" class="column">
            <logo-container :logo="logo.filename" :alt-text="logo.label" :title="logo.label" :value="logo.value" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import LogoContainer from '@/components/LogoContainer.vue';

export default defineComponent({
  components: {
    LogoContainer,
  },
  props: {
    photoSource: {
      type: String,
      required: false,
      default: undefined,
    },
    licencePlate: {
      type: String,
      required: false,
      default: '',
    },
    logos: {
      type: Array,
      required: false,
      default: () => [],
    },
    resourceType: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['apptInFuture', 'apptInPast']),
    filteredLogos() {
      if (this.resourceType === '') return this.logos;
      return this.logos.filter((logo) => {
        if (logo.resourceTypes === undefined) return true; // If no resource type specified then this logo is common to all
        return logo.resourceTypes.find((logoType) => logoType === this.resourceType);
      });
    },
    showLicencePlate() {
      return this.$omwConfig.display.showLicencePlate;
    },
    funFact() {
      if (!this.apptToday) return undefined;
      return this?.engineerDetails?.funFact;
    },
    engineerMessage() {
      if (this.isUnassigned) {
        return '';
      }
      let msg = '';
      if (this.engineerDetails.nameOverride === this.$omwConfig.display.defaultEngineerName) {
        return msg;
      }
      if (!this.engineerName) {
        return this.$t('eng-panel-unknown-engineer');
      }
      if (this.apptInPast) {
        return '';
      }
      if (!this.apptInFuture) {
        if (['pending', 'enroute'].includes(this.activityDetails.status)) {
          msg += this.$t('eng-panel-today-pending', {
            name: this.engineerName,
          });
        } else if (this.activityDetails.status === 'started') {
          msg += this.$t('eng-panel-today-started', {
            name: this.engineerName,
          });
        } else {
          msg += this.$t('eng-panel-today-finished', {
            name: this.engineerName,
          });
        }
      } else {
        msg = this.$t(`eng-panel-future`);
      }
      return msg;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.content {
  background-color: $engineer-panel;
  color: $engineer-panel-text;
  border-radius: 4px;
  box-shadow: 0 0 0 0px black; // invisible outline to prevent margin collapse
  @if variable-exists(engineer-panel-border) {
    border-color: $engineer-panel-border;
    border-style: solid;
    border-width: 1px;
  }
}

.aligned {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.fun-fact-padding {
  @media screen and (min-width: 768px) {
    padding-top: 3rem;
  }
}
.circular--landscape {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.circular--landscape img {
  width: auto;
  height: 100%;
  margin-left: -25px;
}
</style>
