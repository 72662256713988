<template>
  <div class="image-wrapper logo-container has-text-centered">
    <div>
      <img v-if="logo" :src="`img/${logo}`" :alt="altText" :title="title" class="image-logo" />
      <p v-else class="has-text-weight-semibold">{{ title }}</p>
    </div>
    <p class="has-text-weight-semibold is-size-7">
      {{ value }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    logo: {
      type: String,
      required: true,
      default: '',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    altText: {
      type: String,
      required: true,
      default: '',
    },
  },
});
</script>

<style scoped>
.image-logo {
  max-height: 3em;
  width: auto;
}

.logo-container {
  padding-bottom: 1em;
}
</style>
