import { library } from '@fortawesome/fontawesome-svg-core';

import { faEnvelopeOpenText } from '@fortawesome/pro-solid-svg-icons/faEnvelopeOpenText';
import { faFileSignature } from '@fortawesome/pro-solid-svg-icons/faFileSignature';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faMobile } from '@fortawesome/pro-solid-svg-icons/faMobile';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faChevronDoubleDown } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleDown';
import { faChevronDoubleUp } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleUp';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faLeaf } from '@fortawesome/pro-solid-svg-icons/faLeaf';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faTruck } from '@fortawesome/pro-solid-svg-icons/faTruck';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faCalendarDay } from '@fortawesome/pro-solid-svg-icons/faCalendarDay';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faRedo } from '@fortawesome/pro-solid-svg-icons/faRedo';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons/faPaperclip';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faSearchMinus } from '@fortawesome/pro-solid-svg-icons/faSearchMinus';
import { faSearchPlus } from '@fortawesome/pro-solid-svg-icons/faSearchPlus';

library.add([
  faFileSignature,
  faEnvelopeOpenText,
  faMobile,
  faStar,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faArrowDown,
  faArrowUp,
  faCheckCircle,
  faLeaf,
  faExclamationCircle,
  faInfoCircle,
  faQuestionCircle,
  faTruck,
  faTimesCircle,
  faCalendarDay,
  faAngleLeft,
  faAngleRight,
  faEye,
  faEyeSlash,
  faUpload,
  faSearchMinus,
  faSearchPlus,
  faTrash,
  faRedo,
  faPaperclip,
  faExclamationTriangle,
]);
